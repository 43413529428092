// src/components/WalletBalance.js
import React, { useState } from 'react';
import { connectToMetaMask, getBalance } from '../services/metamask.service';
import { checkIfFrozen, freezeWalletWithPIN, unfreezeWalletWithPIN } from '../services';
import { Container, Button, Card, Alert, Form } from 'react-bootstrap';

const WalletBalance = () => {
  const [address, setAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [error, setError] = useState(null);
  const [isFrozen, setIsFrozen] = useState(null);
  const [pin, setPin] = useState('');
  const [lockError, setLockError] = useState(null);
  const [lockSuccess, setLockSuccess] = useState(null);
  const [unlockError, setUnlockError] = useState(null);
  const [unlockSuccess, setUnlockSuccess] = useState(null);

  const handleConnect = async () => {
    try {
      const account = await connectToMetaMask();
      setAddress(account);

      const balance = await getBalance(account);
      setBalance(balance);

      // Check if the wallet is frozen
      const frozenStatus = await checkIfFrozen(account);
      setIsFrozen(frozenStatus);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleLockWallet = async () => {
    if (pin.length !== 6 || isNaN(pin)) {
      setLockError('PIN must be a 6-digit number');
      return;
    }

    try {
      await freezeWalletWithPIN(pin);
      setLockSuccess('Wallet successfully locked');
      setLockError(null);
      setIsFrozen(true);
    } catch (err) {
      setLockError(err.message);
      setLockSuccess(null);
    }
  };

  const handleUnlockWallet = async () => {
    if (pin.length !== 6 || isNaN(pin)) {
      setUnlockError('PIN must be a 6-digit number');
      return;
    }

    try {
      await unfreezeWalletWithPIN(pin);
      setUnlockSuccess('Wallet successfully unlocked');
      setUnlockError(null);
      setIsFrozen(false);
    } catch (err) {
      setUnlockError(err.message);
      setUnlockSuccess(null);
    }
  };

  return (
    <Container className="mt-4">
      <h2 className="text-center">Soruba Chain Wallet</h2>
      {!address && (
        <Button onClick={handleConnect} variant="primary" className="mb-3">
          Connect to MetaMask
        </Button>
      )}

      {error && <Alert variant="danger">{error}</Alert>}

      {address && (
        <Card>
          <Card.Body>
            <Card.Title>Address:</Card.Title>
            <Card.Text>{address}</Card.Text>
            <Card.Title>Balance:</Card.Title>
            <Card.Text>{balance} SRB</Card.Text>
            <Card.Title>Status:</Card.Title>
            <Card.Text>{isFrozen ? 'Locked' : 'Unlocked'}</Card.Text>
            <Form.Group controlId="formPin">
              <Form.Label>Enter 6-digit PIN</Form.Label>
              <Form.Control
                type="password"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                maxLength="6"
              />
            </Form.Group>
            {isFrozen ? (
              <>
                <Button onClick={handleUnlockWallet} variant="success" className="mt-3">
                  Unlock Wallet
                </Button>
                {unlockError && <Alert variant="danger" className="mt-3">{unlockError}</Alert>}
                {unlockSuccess && <Alert variant="success" className="mt-3">{unlockSuccess}</Alert>}
              </>
            ) : (
              <>
                <Button onClick={handleLockWallet} variant="danger" className="mt-3">
                  Lock Wallet
                </Button>
                {lockError && <Alert variant="danger" className="mt-3">{lockError}</Alert>}
                {lockSuccess && <Alert variant="success" className="mt-3">{lockSuccess}</Alert>}
              </>
            )}
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};

export default WalletBalance;
