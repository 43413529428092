// src/App.js
import React from 'react';
import WalletBalance from './components/WalletBalance';

function App() {
  return (
    <div>
      <WalletBalance />
    </div>
  );
}

export default App;
