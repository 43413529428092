import { getProvider, getSigner } from './metamask.service';
import { ethers } from 'ethers';
import { rulesEngineABI } from '../abi/rulesEngineABI';

const contractAddress = process.env.REACT_APP_RULES_ENGINE_ADDRESS;

// Get contract instance
export function getContract() {
  const provider = getProvider();
  const signer = getSigner();
  if (!provider || !signer) {
    throw new Error("Provider or signer not initialized. Please connect to MetaMask first.");
  }
  return new ethers.Contract(contractAddress, rulesEngineABI, signer);
}

// Check if the wallet is frozen
export async function checkIfFrozen(walletAddress) {
  const contract = getContract();
  try {
    // Ensure the wallet address is in a valid format
    if (!ethers.isAddress(walletAddress)) {
      throw new Error("Invalid wallet address");
    }
    const isFrozen = await contract.checkIfFrozen(walletAddress);
    console.log(`Wallet ${walletAddress} is frozen: ${isFrozen}`);
    return isFrozen;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Execute freezeWalletWithPIN method
export async function freezeWalletWithPIN(pin) {
  const contract = getContract();
  try {
    const tx = await contract.freezeWalletWithPIN(pin);
    await tx.wait(); // Wait for the transaction to be mined
    console.log(`Wallet frozen with a PIN on transaction: ${tx.hash}`);
    return tx;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Execute unfreezeWalletWithPIN method
export async function unfreezeWalletWithPIN(pin) {
  const contract = getContract();
  try {
    const tx = await contract.unfreezeWalletWithPIN(pin);
    await tx.wait(); // Wait for the transaction to be mined
    console.log(`Wallet unfrozen with a PIN on transaction: ${tx.hash}`);
    return tx;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

