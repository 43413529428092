import { ethers } from 'ethers';

let provider;
let signer;

const chainConfig = {
  chainId: `0x${parseInt(process.env.REACT_APP_CHAIN_ID, 10).toString(16)}`,
  chainName: process.env.REACT_APP_CHAIN_NAME,
  rpcUrls: [process.env.REACT_APP_RPC_URL],
  nativeCurrency: {
    name: process.env.REACT_APP_NATIVE_CURRENCY_NAME,
    symbol: process.env.REACT_APP_NATIVE_CURRENCY_SYMBOL,
    decimals: Number(process.env.REACT_APP_NATIVE_CURRENCY_DECIMALS),
  },
  blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER_URL],
};

// Initialize provider and signer
async function initializeProvider() {
  if (!window.ethereum) {
    throw new Error("MetaMask is not installed");
  }
  provider = new ethers.BrowserProvider(window.ethereum);
  signer = await provider.getSigner();
}

// Check if MetaMask is connected to the correct chain
async function ensureCorrectChain() {
  const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

  if (currentChainId !== chainConfig.chainId) {
    try {
      // Try to switch to the correct chain
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainConfig.chainId }],
      });
    } catch (switchError) {
      // If the chain is missing, try to add it
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chainConfig],
          });
        } catch (addError) {
          console.error("Failed to add chain to MetaMask", addError);
          throw addError;
        }
      } else {
        console.error("Failed to switch chain in MetaMask", switchError);
        throw switchError;
      }
    }
  }
}

// Connect to MetaMask and initialize provider
export async function connectToMetaMask() {
  try {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    await initializeProvider();
    await ensureCorrectChain();
    const address = await signer.getAddress();
    return address;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Get wallet balance
export async function getBalance(address) {
  if (!provider) {
    throw new Error("Provider not initialized. Please connect to MetaMask first.");
  }

  try {
    const balance = await provider.getBalance(address);
    return parseFloat(ethers.formatEther(balance)).toFixed(8); // Format to Ether and round to 8 decimal places
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Export provider and signer
export function getProvider() {
  return provider;
}

export function getSigner() {
  return signer;
}
